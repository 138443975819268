
import React from 'react'
import Landing from '../components/landing'

import Seo from '../components/seo'

const HomePage = () => (
  <>
    <Seo title='Home' />
    <Landing />
  </>
)

export default HomePage